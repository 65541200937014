<template>
  <el-main>
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="服务名称：">
        <el-input v-model="searchForm.goods_name" size="small" placeholder="请输入服务名称"></el-input>
      </el-form-item>
      <el-form-item label="服务分类：">
        <el-cascader size="small" clearable :props="{ value: 'id', label: 'name', children: '_child' }" v-model="searchForm.classify_id" :options="goodsClass"></el-cascader>
      </el-form-item>
      <el-form-item label="服务来源：">
        <el-select v-model="searchForm.source" size="small">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="平台服务" :value="-1"></el-option>
          <el-option label="会员服务" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="来源店铺名称：">
        <el-input v-model="searchForm.enterprise_name" size="small" placeholder="请输入来源店铺名称"></el-input>
      </el-form-item>
      <el-form-item label="来源店铺电话：">
        <el-input v-model="searchForm.mobile" size="small" placeholder="请输入来源店铺电话："></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button size="small" @click="search" type="primary">搜索</el-button>
        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="consignmentGoods" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column label="服务主图" align="center" width="80px">
        <template v-slot="{ row }">
          <el-image style="width: 60px; height: 60px" :src="row.goods_cover_picture ? row.goods_cover_picture : require('@/assets/default-img.png')"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="classify_name" label="服务分类" align="center"></el-table-column>
      <el-table-column prop="service_name" label="服务名称" align="center"></el-table-column>
      <el-table-column label="服务价格" align="center">
        <template v-slot="{ row }">{{ row.service_price }}</template>
      </el-table-column>
      <el-table-column prop="sales" label="销量" align="center"></el-table-column>
      <el-table-column prop="member_ids" label="服务来源" align="center">
        <template v-slot="{ row }">{{ row.member_ids == 0 ? '平台服务' : '会员服务' }}</template>
      </el-table-column>
      <el-table-column prop="enterprise_name" label="来源店铺名称" align="center"></el-table-column>
      <el-table-column prop="mobile" label="来源店铺电话" align="center"></el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="200px">
        <template v-slot="{ row }">
          <el-button type="text" @click="soldOut(row.id)">下架</el-button>
          <el-button type="text" @click="$router.push('/gateway/service/serviceEdit?id=' + row.id)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="下架" :visible.sync="showSoldOut" width="650px">
      <el-form>
        <el-form-item label="请添加下架原因：">
          <el-input v-model="remark" type="textarea" :rows="5"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showSoldOut = !1">取 消</el-button>
        <el-button type="primary" @click="confirmSoldOut">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      activeName: '1',
      id: '',
      searchForm: {
        page: 1,
        rows: 10,
        classify_id: '',
        goods_name: '',
        source: 0,
        enterprise_name: '',
        mobile: '',
      },
      total_number: 0,
      goodsClass: [],
      consignmentGoods: [],
      showSoldOut: !1,
      remark: '',
    };
  },
  created() {
    this.$axios.post(this.$api.goods.goodsClass).then((res) => {
      if (res.code == 0) {
        this.goodsClass = res.result;
      }
    });
    this.getConsignmentGoods();
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getConsignmentGoods();
    },
    search() {
      this.searchForm.page = 1;
      this.getConsignmentGoods();
    },
    searchClear() {
      this.searchForm = {
        page: 1,
        rows: 10,
        classify_id: '',
        goods_name: '',
        source: 0,
        enterprise_name: '',
        mobile: '',
      };
      this.getConsignmentGoods();
    },
    soldOut(id) {
      this.id = id;
      this.remark = '';
      this.showSoldOut = !0;
    },
    confirmSoldOut() {
      if (!this.remark) return this.$message.warning('请填写下架原因');
      this.$axios
        .post(this.$api.shop.soldServiceOut, {
          member_id: this.member_id,
          id: this.id,
          remark: this.remark,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$message.success('下架成功');
            this.getList();
            this.showSoldOut = !1;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getConsignmentGoods() {
      this.$axios
        .post(this.$api.shop.consignmentService, {
          ...this.searchForm,
          member_id: this.member_id,
        })
        .then((res) => {
          if (res.code == 0) {
            this.consignmentGoods = res.result.list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>